import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { slick } from './partials/reactSlick.style'
import { slickTheme } from './partials/reactSlickTheme.style'
import { colors } from './vars/colors.style'
import { zIndex } from './vars/zIndex.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}
  ${slick}
  ${slickTheme}

  :root {
    --reach-tabs: 1;
    --reach-dialog: 1;
  }

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  body {
    background-color: ${colors.lightGrey};

    &.disable-scroll {
      overflow: hidden;
    }
  }

  [data-reach-dialog-overlay] {
    z-index: ${zIndex.modal};
  }

  a {
    color: ${colors.blue};
  }

  #portal {
    position: relative;
    z-index: ${zIndex.portal};
  }

  div.dg.ac {
    z-index: 100;
  }

  .super {
    display: inline-block;
    font-style: normal;
    
    .split__text & {
      vertical-align: super;
      font-size: 0.208em;
    }
  }
`
