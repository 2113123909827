import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'

export const AnimateSlideInMask = styled.span`
  display: block;
  /* overflow: hidden; */
`

const BaseAnimateSlideInContent = styled.span`
  display: block;
  transition-property: transform, opacity;
  transition-timing-function: ${easings.inOut.default};
`

export const AnimateSlideInContent = styled(BaseAnimateSlideInContent).attrs(
  props => {
    return {
      style: {
        transitionDuration: `${props.duration}s`,
        transitionDelay: `${props.delay}s`,
      },
    }
  }
)`
  opacity: ${props => (props.inView ? 1 : 0)};
  transform: ${({ direction, inView }) => {
    let offset

    switch (direction) {
      case 'top':
        offset = '0, -50%'
        break

      case 'right':
        offset = '50%, 0%'
        break

      case 'bottom':
        offset = '0%, 50%'
        break

      case 'left':
        offset = '-50%, 0%'
        break

      default:
        break
    }

    return `translate(${inView ? '0, 0' : offset})`
  }};
`
