import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import { StoreState, StoreDispatch } from '../../Store'
import { disablePageScroll, enablePageScroll } from '../../utils/utils'
import { MenuToggleMain, MenuToggleBar, MenuToggleWrapper } from './index.style'

const MenuToggle = ({ light }) => {
  const store = useContext(StoreState)
  const { mobileNavIsOpen } = store
  const dispatch = useContext(StoreDispatch)
  const toggleRef = useRef()
  const barRefA = useRef()
  const barRefB = useRef()

  useEffect(() => {
    const tlOpen = gsap.timeline({ paused: true })
    const tlClose = gsap.timeline({ paused: true })

    gsap.defaults({
      duration: 0.15,
      ease: 'power3.inOut',
    })

    tlOpen
      .to(barRefA.current, {
        y: 0,
        rotate: 45,
      })
      .to(
        barRefB.current,
        {
          y: 0,
          rotate: -45,
        },
        0
      )

    tlClose
      .to(barRefA.current, {
        y: -4,
        rotate: 0,
      })
      .to(
        barRefB.current,
        {
          y: 4,
          rotate: 0,
        },
        0
      )

    if (mobileNavIsOpen) {
      disablePageScroll()
      tlOpen.play()
    } else {
      enablePageScroll()
      tlClose.play()
    }

    return () => {
      tlOpen.kill()
      tlClose.kill()
    }
  }, [mobileNavIsOpen])

  const handleClick = () => {
    if (mobileNavIsOpen) {
      dispatch({ type: 'HIDE_MOBILE_NAV' })
    } else {
      dispatch({ type: 'SHOW_MOBILE_NAV' })
    }
  }

  return (
    <MenuToggleWrapper ref={toggleRef}>
      <MenuToggleMain onClick={handleClick} aria-label="Toggle menu">
        <MenuToggleBar ref={barRefA} light={mobileNavIsOpen || light} />
        <MenuToggleBar ref={barRefB} light={mobileNavIsOpen || light} />
      </MenuToggleMain>
    </MenuToggleWrapper>
  )
}

export default MenuToggle
