import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'
import { animation } from '../../../styles/vars/animation.style'
import backgroundGradientImage from '../../TextGradient/images/text-gradient-background-compressed.jpeg'

export const SplitTextWrapper = styled.span`
  display: block;
  opacity: ${props => (props.show ? 1 : 0)};
  background-image: ${props =>
    props.show ? undefined : `url(${backgroundGradientImage})`};

  .split__text {
    display: inline-block;
    will-change: transform, opacity;
    transform: translateY(${props => (props.show ? '0%' : '50%')});
    opacity: ${props => (props.show ? 1 : 0)};
    transition: transform 0.5s ${easings.inOut.default},
      opacity 0.5s ${easings.inOut.default};
    transition-delay: ${props => {
      if (props.show) {
        if (props.heading) {
          return `calc(${props.delay}s + ${animation.headingLineDuration} * var(--splitTextDelay))`
        } else {
          const animationDelay =
            props.type.indexOf('chars') > -1
              ? animation.charDuration
              : props.type.indexOf('words') > -1
              ? animation.wordDuration
              : animation.lineDuration
          return `calc(${props.delay}s + ${animationDelay} * var(--splitTextDelay))`
        }
      }
    }};

    ${({ heading }) => {
      if (heading) {
        return `
        color: transparent;
        background-image: url(${backgroundGradientImage});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-background-clip: text;
        background-clip: text;
        `
      }
    }}
  }
`
