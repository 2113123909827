export const zIndex = {
  header: 10,
  mobileNav: 12,
  mobileNavButtons: 13,
  portal: 20,
  cookieBar: 25,
  modal: 28,
  cursor: 29,
  pageTransitionMask: 30,
}
