import styled from 'styled-components'
import { zIndex } from '../../styles/vars/zIndex.style'

export const ScrollSectionMain = styled.div`
  position: ${props => (props.header ? 'relative' : undefined)};
  will-change: transform, opacity;
  z-index: ${props => (props.header ? zIndex.header : undefined)};
`

export const ScrollBlock = styled.div`
  position: relative;
`
