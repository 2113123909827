import React, { useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import {
  HowAccordionContentMain,
  HowAccordionContentWrapper,
  HowAccordionContentInner,
  HowAccordionScrollProgress,
  HowAccordionContentBorder,
} from './index.style'
import { Heading2 } from '../../TextStyles'
import RichText from '../../RichText'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const HowAccordionContent = ({ open, title, content, progressIndex }) => {
  const contentRef = useRef(null)
  const [contentHeight, setContentHeight] = useState(null)

  useEffect(() => {
    const updateHeight = debounce(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.offsetHeight)
      }
    }, 700)

    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  return (
    <HowAccordionContentMain open={open}>
      <Heading2 as="h3">
        <AnimateSplitText>{title}</AnimateSplitText>
      </Heading2>

      <HowAccordionContentWrapper height={contentHeight} open={open}>
        <HowAccordionContentInner ref={contentRef} open={open}>
          <RichText content={content} paragraphSize="small" />
        </HowAccordionContentInner>
      </HowAccordionContentWrapper>

      <AnimateSlideIn>
        <HowAccordionContentBorder>
          <HowAccordionScrollProgress
            progressIndex={progressIndex}
            open={open}
          />
        </HowAccordionContentBorder>
      </AnimateSlideIn>
    </HowAccordionContentMain>
  )
}

HowAccordionContent.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.object,
  progressIndex: PropTypes.number,
}

export default HowAccordionContent
