import React, { useReducer, createContext } from 'react'

export const StoreState = createContext()
export const StoreDispatch = createContext()

const initialState = {
  showTransitionMask: true,
  mobileNavIsOpen: false,
  headerIsLight: true,
  fontIsLoaded: false,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  popstate: 0,
  showCursor: false,
  viewport: {
    width: typeof window === 'undefined' ? null : window.innerWidth,
    height: typeof window === 'undefined' ? null : window.innerHeight,
  },
  howAccordionActiveIndex: -1,
  mousePosition: {
    x: 0,
    y: 0,
  },
  homeTabsSwitchIndex: 0,
  newsletterPopUpIsOpen: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.payload,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SET_HEADER_LIGHT': {
      return {
        ...state,
        headerIsLight: true,
      }
    }
    case 'SET_HEADER_DARK': {
      return {
        ...state,
        headerIsLight: false,
      }
    }

    case 'SWITCH_HOME_TAB': {
      return {
        ...state,
        homeTabsSwitchIndex: state.homeTabsSwitchIndex + 1,
      }
    }

    case 'SHOW_CURSOR': {
      return {
        ...state,
        showCursor: true,
      }
    }
    case 'HIDE_CURSOR': {
      return {
        ...state,
        showCursor: false,
      }
    }

    case 'LOAD_FONTS': {
      return {
        ...state,
        fontIsLoaded: true,
      }
    }

    case 'SHOW_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: true,
      }
    }
    case 'HIDE_TRANSITION_MASK': {
      return {
        ...state,
        showTransitionMask: false,
      }
    }

    case 'SHOW_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: true,
      }
    }
    case 'HIDE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: false,
      }
    }
    case 'TOGGLE_MOBILE_NAV': {
      return {
        ...state,
        mobileNavIsOpen: !state.mobileNavIsOpen,
      }
    }

    case 'UPDATE_VIEWPORT': {
      return {
        ...state,
        viewport: action.payload,
      }
    }

    case 'SET_MOUSE_POSITION': {
      return {
        ...state,
        mousePosition: action.payload,
      }
    }

    case 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION': {
      return {
        ...state,
        howAccordionActiveIndex: action.payload,
      }
    }

    case 'OPEN_NEWSLETTER_POP_UP': {
      return {
        ...state,
        newsletterPopUpIsOpen: true,
      }
    }
    case 'CLOSE_NEWSLETTER_POP_UP': {
      return {
        ...state,
        newsletterPopUpIsOpen: false,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

const Store = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StoreState.Provider value={state}>
      <StoreDispatch.Provider value={dispatch}>
        {props.children}
      </StoreDispatch.Provider>
    </StoreState.Provider>
  )
}

export default Store
