import '../../../globals'
import React, { useEffect, useContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'
import { useStaticQuery, graphql } from 'gatsby'
import { isDesktop } from 'react-device-detect'

// Components
import { StoreDispatch, StoreState } from '../../Store'
import TransitionMask from '../PageTransitionMask'
import ScrollSection from '../ScrollSection'
import Gridlines from '../Gridlines'
import Header from '../Header'
import Scroll from '../Scroll'
import Cursor from '../Cursor'
import CookieBar from '../CookieBar'
import Modal from '../Modal'

// Fonts
import RoobertLightWOFF from '../../fonts/Roobert/Roobert-Light.woff'
import RoobertLightWOFF2 from '../../fonts/Roobert/Roobert-Light.woff2'
import RoobertRegularWOFF from '../../fonts/Roobert/Roobert-Regular.woff'
import RoobertRegularWOFF2 from '../../fonts/Roobert/Roobert-Regular.woff2'
import RoobertMediumWOFF from '../../fonts/Roobert/Roobert-Medium.woff'
import RoobertMediumWOFF2 from '../../fonts/Roobert/Roobert-Medium.woff2'
import RoobertSemiBoldWOFF from '../../fonts/Roobert/Roobert-SemiBold.woff'
import RoobertSemiBoldWOFF2 from '../../fonts/Roobert/Roobert-SemiBold.woff2'

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { font } from '../../styles/vars/font.style'
import NewsletterPopUp from '../NewsletterPopUp'

const Layout = ({ children, location }) => {
  const {
    contentfulNewsletterPopUp: { title: popUpTitle },
  } = useStaticQuery(graphql`
    query {
      contentfulNewsletterPopUp {
        title
      }
    }
  `)
  const store = useContext(StoreState)
  const { newsletterPopUpIsOpen } = store
  const dispatch = useContext(StoreDispatch)
  const [showNewsletter, setShowNewsletter] = useState(false)

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      dispatch({
        type: 'UPDATE_PATHNAME',
        payload: window.location.pathname,
      })
      dispatch({ type: 'UPDATE_POP_STATE' })
    },
    [dispatch]
  )

  const setViewportHeight = useCallback(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    dispatch({
      type: 'UPDATE_VIEWPORT',
      payload: { width: window.innerWidth, height: window.innerHeight },
    })
  }, [dispatch])

  const updateMousePosition = useCallback(
    e => {
      dispatch({
        type: 'SET_MOUSE_POSITION',
        payload: { x: e.clientX, y: e.clientY },
      })
    },
    [dispatch]
  )

  const handleWebfontLoad = useCallback(() => {
    const observers = []
    const fontData = {
      [font.primary.family]: { weight: font.primary.weight.light },
      [font.primary.family]: { weight: font.primary.weight.regular },
      [font.primary.family]: { weight: font.primary.weight.medium },
      [font.primary.family]: { weight: font.primary.weight.semibold },
    }

    Object.keys(fontData).forEach(family => {
      const data = fontData[family]
      const obs = new FontFaceObserver(family, data)
      observers.push(obs.load())
    })

    Promise.all(observers).then(
      () => {
        dispatch({ type: 'LOAD_FONTS' })
      },
      () => {
        console.log('Font is not available')
      }
    )
  }, [dispatch])

  useEffect(() => {
    setViewportHeight()
    handleWebfontLoad()

    document.addEventListener('mousemove', updateMousePosition, false)
    window.addEventListener('resize', setViewportHeight)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    const timeout = setTimeout(() => {
      setShowNewsletter(true)
    }, 15000)

    return () => {
      clearTimeout(timeout)
      document.removeEventListener('mousemove', updateMousePosition)
      window.removeEventListener('resize', setViewportHeight)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [
    dispatch,
    setViewportHeight,
    handleWebfontLoad,
    updateMousePosition,
    handleBrowserNavigationInteraction,
  ])

  useEffect(() => {
    if (!store.showTransitionMask && showNewsletter) {
      dispatch({ type: 'OPEN_NEWSLETTER_POP_UP' })
      setShowNewsletter(false)
    }
  }, [store.showTransitionMask, showNewsletter, dispatch])
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <style>{`
          @font-face {
            font-family: ${font.primary.family};
            src: url(${RoobertLightWOFF}) format('woff'),
              url(${RoobertLightWOFF2}) format('woff2');
            font-weight: ${font.primary.weight.light};font-display: swap;
          }
          @font-face {
            font-family: ${font.primary.family};
            src: url(${RoobertRegularWOFF}) format('woff'),
              url(${RoobertRegularWOFF2}) format('woff2');
            font-weight: ${font.primary.weight.regular};font-display: swap;
          }
          @font-face {
            font-family: ${font.primary.family};
            src: url(${RoobertMediumWOFF}) format('woff'),
              url(${RoobertMediumWOFF2}) format('woff2');
            font-weight: ${font.primary.weight.medium};font-display: swap;
          }
          @font-face {
            font-family: ${font.primary.family};
            src: url(${RoobertSemiBoldWOFF}) format('woff'),
              url(${RoobertSemiBoldWOFF2}) format('woff2');
            font-weight: ${font.primary.weight.semibold};font-display: swap;
          }
        `}</style>
      </Helmet>

      <Gridlines show={false} />

      <Scroll
        callbacks={location}
        desktop={store.viewport.width >= breakpoints.tabletL}
      />

      <TransitionMask />

      <div id="scroll-container" data-scroll-container>
        <ScrollSection header>
          <Header pathname={location.pathname} />
        </ScrollSection>

        {children}

        <CookieBar />
      </div>

      <Modal
        isOpen={newsletterPopUpIsOpen}
        onDismiss={() => dispatch({ type: 'CLOSE_NEWSLETTER_POP_UP' })}
        ariaLabel={popUpTitle}
      >
        <NewsletterPopUp />
      </Modal>

      {isDesktop && (
        <Cursor x={store.mousePosition.x} y={store.mousePosition.y} />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
