import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'

export const ContainerMain = styled.div`
  width: 100%;
  max-width: ${props =>
    props.fullWidth
      ? undefined
      : `${(breakpoints.contained + 48 * 2) / 10}rem`};
  margin: 0 auto;
  ${clamp(
    'padding-left',
    24,
    48,
    breakpoints.mobile,
    breakpoints.tabletL,
    false
  )}
  ${clamp(
    'padding-right',
    24,
    48,
    breakpoints.mobile,
    breakpoints.tabletL,
    false
  )}
  overflow: ${props => (props.allowOverflow ? undefined : 'hidden')};
`
