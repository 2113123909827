import React from 'react'
import PropTypes from 'prop-types'
import { ContainerMain } from './index.style'

const Container = ({ fullWidth, children, allowOverflow }) => {
  return (
    <ContainerMain fullWidth={fullWidth} allowOverflow={allowOverflow}>
      {children}
    </ContainerMain>
  )
}

Container.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  allowOverflow: PropTypes.bool,
}

Container.defaultProps = {
  allowOverflow: false,
  fullWidth: false,
}

export default Container
