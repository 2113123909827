import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ContactFormField, ContactFormInput } from '../ContactForm/index.style'

export const NewsletterPopUpMain = styled.div`
  position: relative;
  width: 100%;
  max-width: 105.4rem;
  overflow: hidden;
  background-color: ${colors.light};
  ${clamp('border-radius', 12, 24)}

  ${mq.tabletL} {
    display: flex;
  }
`

export const NewsletterPopUpContent = styled.div`
  ${clamp('padding-top', 26, 88)}
  ${clamp('padding-bottom', 32, 70)}
  ${clamp('padding-right', 24, 68)}
  ${clamp('padding-left', 24, 68)}

  ${mq.tabletL} {
    width: 70%;
  }

  h2 {
    padding-right: 5rem;
  }

  form {
    ${mq.tabletL} {
      display: flex;
    }
  }

  ${ContactFormField} {
    margin-bottom: 0;

    ${mq.tabletL} {
      flex: 1;
    }
  }

  ${ContactFormInput} {
    background-color: transparent;

    ${mq.tabletL} {
      border-radius: 3.5rem 0 0 3.5rem;
    }
  }
`

export const NewsletterImage = styled.div`
  width: 30%;

  ${mq.tabletLMax} {
    display: none;
  }
`

export const NewsletterClose = styled.button`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  ${clamp('width', 44, 58)}
  ${clamp('height', 44, 58)}
  cursor: pointer;
  z-index: 1;
  border: none;
  border-radius: 50%;
  background-color: transparent;

  ${mq.tabletL} {
    color: ${colors.light};
  }

  :focus {
    outline: none;
  }
`

export const NewsletterSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1.4em 1.15em;
  cursor: pointer;
  ${clamp('font-size', 16, 20)}
  line-height: 1.35;
  letter-spacing: -0.01em;
  color: ${colors.lightGrey};
  border: none;
  border-radius: 3.5rem;
  background-color: ${colors.blue};

  ${mq.tabletLMax} {
    width: 100%;
    margin-top: 1.2rem;
  }

  ${mq.tabletL} {
    border-radius: 0 3.5rem 3.5rem 0;
  }

  * + * {
    margin-left: 0.7rem;
  }
`
