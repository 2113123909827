import styled, { css } from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { clamp } from '../../../styles/utils/conversion.style'
import { Heading2 } from '../../../styles/vars/textStyles.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { transparentize } from 'polished'
import { setVh } from '../../../styles/utils/conversion.style'
import { easings } from '../../../styles/vars/easings.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'

import backgroundGradientImage from './assets/text-background.jpg'
import { font } from '../../../styles/vars/font.style'

export const HowAccordionWrapper = styled.div`
  position: relative;
`

export const HowAccordionMain = styled.div`
  ${clamp('padding-bottom', 40, 240)}
  border-top: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
`

export const HowAccordionNav = styled.ul`
  display: flex;
  list-style: none;
  ${clamp(
    'margin-right',
    -24,
    -48,
    breakpoints.mobile,
    breakpoints.tabletL,
    false
  )}
  ${clamp(
    'padding-right',
    24,
    48,
    breakpoints.mobile,
    breakpoints.tabletL,
    false
  )}
  margin-bottom: 1.3rem;
  padding-bottom: 2rem;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${mq.tabletL} {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.desk} {
    display: none;
  }
`

export const HowAccordionNavItem = styled.li`
  & + & {
    margin-left: ${24 / 28}em;
  }

  ${Heading2} {
    color: ${props =>
      props.open ? colors.blue : transparentize(0.5, colors.midGrey)};
    transition: color 0.2s ${easings.inOut.default};
  }
`

export const HowAccordionNavButton = styled.button`
  border: none;
  background: transparent;
`

const minFontSize = 102
const maxFontSize = 244

export const HowAccordionBox = styled.div`
  background-color: ${colors.darkGrey};
  ${clamp('border-radius', 16, 24)}
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  :before {
    content: '';
    display: block;
    ${clamp('height', minFontSize * 3, maxFontSize * 3)}
  }

  ${mq.tabletL} {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  ${mq.desk} {
    max-width: unset;
  }
`

export const HowAccordionSpacer = styled.div`
  ${mq.desk} {
    ${setVh('height', 100)}
  }
`

export const AccordionImage = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }

  ${({ slideIndex, activeIndex }) => {
    return slideIndex === activeIndex
      ? `
        opacity: 1; 
        transform: translateY(0); 
        transition: opacity .6s ${easings.inOut.default} .6s, transform .6s ${easings.inOut.default} .6s;`
      : slideIndex < activeIndex
      ? `
      opacity: 0; 
      transform: translateY(-5rem); 
      transition: opacity .6s ${easings.inOut.default},  transform .6s ${easings.inOut.default};`
      : `
      opacity: 0; 
      transform: translateY(5rem); 
      transition: opacity .6s ${easings.inOut.default},  transform .6s ${easings.inOut.default};`
  }}
`

export const HowAccordionNumbers = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  ${clamp('left', 24, 60)}
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  z-index: 5;
`

export const HowAccordionNumberInner = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.darkGrey};
  transition: background-color 0.5s ease;
  transform-origin: center center;
`

const HowAccordionNumberTextStyles = css`
  display: inline-block; // needed for safari
  font-weight: ${font.primary.weight.light};
  line-height: 1;
  letter-spacing: -0.07em;
  -webkit-background-clip: text;
  background-clip: text;
`

export const HowAccordionNumberText = styled.p`
  ${HowAccordionNumberTextStyles}
  position: relative;
  width: 1.1em;
  height: 1em;
  color: #383839;
`

export const HowAccordionNumberTextBackground = styled.span`
  ${HowAccordionNumberTextStyles}
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  left: 0.2rem;
  color: transparent;
  background-image: url(${backgroundGradientImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
`

export const HowAccordionNumber = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 1em;
  ${clamp('font-size', minFontSize * 0.7, maxFontSize)}
  line-height: 1;
  will-change: transform;

  :nth-child(1) {
    top: 0.61em;

    ${HowAccordionNumberText}, ${HowAccordionNumberTextBackground} {
      letter-spacing: 0.01em;
    }
  }

  :nth-child(2) {
    z-index: 3;
  }

  :nth-child(3) {
    top: -0.61em;
    z-index: 4;
  }
`
