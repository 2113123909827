import React from 'react'
import PropTypes from 'prop-types'
import { GridItemMain } from './index.style'
import { grid } from '../../styles/vars/grid.style'

const GridItem = ({
  id,
  children,
  colCount,
  tiny,
  mobile,
  mobileL,
  tabletP,
  tabletL,
  desk,
  deskL,
  contained,
  tinyStart,
  mobileStart,
  mobileLStart,
  tabletPStart,
  tabletLStart,
  deskStart,
  deskLStart,
  containedStart,
  tinyOrder,
  mobileOrder,
  mobileLOrder,
  tabletPOrder,
  tabletLOrder,
  deskOrder,
  deskLOrder,
  containedOrder,
  tinyAlign,
  mobileAlign,
  mobileLAlign,
  tabletPAlign,
  tabletLAlign,
  deskAlign,
  deskLAlign,
  containedAlign,
}) => (
  <GridItemMain
    id={id}
    colCount={colCount}
    tiny={tiny}
    mobile={mobile}
    mobileL={mobileL}
    tabletP={tabletP}
    tabletL={tabletL}
    desk={desk}
    deskL={deskL}
    contained={contained}
    tinyStart={tinyStart}
    mobileStart={mobileStart}
    mobileLStart={mobileLStart}
    tabletPStart={tabletPStart}
    tabletLStart={tabletLStart}
    deskStart={deskStart}
    deskLStart={deskLStart}
    containedStart={containedStart}
    tinyOrder={tinyOrder}
    mobileOrder={mobileOrder}
    mobileLOrder={mobileLOrder}
    tabletPOrder={tabletPOrder}
    tabletLOrder={tabletLOrder}
    deskOrder={deskOrder}
    deskLOrder={deskLOrder}
    containedOrder={containedOrder}
    tinyAlign={tinyAlign}
    mobileAlign={mobileAlign}
    mobileLAlign={mobileLAlign}
    tabletPAlign={tabletPAlign}
    tabletLAlign={tabletLAlign}
    deskAlign={deskAlign}
    deskLAlign={deskLAlign}
    containedAlign={containedAlign}
  >
    {children}
  </GridItemMain>
)

GridItem.propTypes = {
  children: PropTypes.node,
  colCount: PropTypes.number,
  tiny: PropTypes.number,
  mobile: PropTypes.number,
  mobileL: PropTypes.number,
  tabletP: PropTypes.number,
  tabletL: PropTypes.number,
  desk: PropTypes.number,
  deskL: PropTypes.number,
  contained: PropTypes.number,
  tinyStart: PropTypes.number,
  mobileStart: PropTypes.number,
  mobileLStart: PropTypes.number,
  tabletPStart: PropTypes.number,
  tabletLStart: PropTypes.number,
  deskStart: PropTypes.number,
  deskLStart: PropTypes.number,
  containedStart: PropTypes.number,
  tinyOrder: PropTypes.number,
  mobileOrder: PropTypes.number,
  mobileLOrder: PropTypes.number,
  tabletPOrder: PropTypes.number,
  tabletLOrder: PropTypes.number,
  deskOrder: PropTypes.number,
  deskLOrder: PropTypes.number,
  containedOrder: PropTypes.number,
  tinyAlign: PropTypes.string,
  mobileAlign: PropTypes.string,
  mobileLAlign: PropTypes.string,
  tabletPAlign: PropTypes.string,
  tabletLAlign: PropTypes.string,
  deskAlign: PropTypes.string,
  deskLAlign: PropTypes.string,
  containedAlign: PropTypes.string,
}

GridItem.defaultProps = {
  colCount: grid.colCount,
}

export default GridItem
