import styled from 'styled-components'
import { easings } from '../../styles/vars/easings.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const CtaWrapper = styled.div`
  display: inline-flex;

  a,
  button {
    p {
      transform: translateX(0);
      transition: transform 0.35s ${easings.inOut.default};
      transition-delay: 0s;
    }

    &:hover {
      p {
        transform: translateX(2.4rem);
        transition-delay: 0.15s;
      }
    }
  }
`

export const CtaLink = styled.a`
  border: none;
  background-color: transparent;
  cursor: pointer;
`

export const CtaMain = styled.span`
  display: flex;
  align-items: center;
  color: ${props => (props.color ? props.color : 'currentColor')};
  position: relative;
`

export const ArrowMask = styled.span`
  display: inline-block;
  overflow: hidden;
  position: relative;

  transform: translateX(0);
  transition-delay: 0.25s;
  transition: transform 0.25s ${easings.inOut.default};

  svg {
    display: inline-block;
    transform: translateX(0);
    transition-delay: 0.25s;
    transition: transform 0.25s ${easings.inOut.default};
  }

  ${PageTransitionLinkMain}:hover & {
    transform: translateX(100%);
    transition-delay: 0s;

    svg {
      transform: translateX(-100%);
      transition-delay: 0s;
    }
  }

  ${CtaLink}:hover & {
    transform: translateX(100%);
    transition-delay: 0s;

    svg {
      transform: translateX(-100%);
      transition-delay: 0s;
    }
  }
`

export const ArrowMaskHover = styled.span`
  display: inline-block;
  overflow: hidden;
  position: absolute;
  transform: translateX(-100%);
  transition-delay: 0s;
  transition: transform 0.25s ${easings.inOut.default};

  svg {
    display: inline-block;
    transform: translateX(100%);
    transition-delay: 0s;
    transition: transform 0.25s ${easings.inOut.default};
  }

  ${PageTransitionLinkMain}:hover & {
    transform: translateX(0);
    transition-delay: 0.25s;

    svg {
      transform: translateX(0);
      transition-delay: 0.25s;
    }
  }

  ${CtaLink}:hover & {
    transition-delay: 0.25s;
    transform: translateX(0);

    svg {
      transition-delay: 0.25s;
      transform: translateX(0);
    }
  }
`
