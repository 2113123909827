import React from 'react'

const IconClose = ({
  width = 58,
  height = 58,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      viewBox="0 0 58 58"
      aria-label="Close"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M29 57c15.464 0 28-12.536 28-28S44.464 1 29 1 1 13.536 1 29s12.536 28 28 28Zm0 1c16.016 0 29-12.984 29-29S45.016 0 29 0 0 12.984 0 29s12.984 29 29 29Z"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="m37.693 20.95-.642-.642-7.705 7.704-7.704-7.704-.642.642 7.704 7.704L21 36.358l.642.642 7.704-7.704L37.05 37l.642-.642-7.704-7.704 7.705-7.704Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default IconClose
