import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import HowAccordionContent from '../HowAccordionContent'
import ScrollSection from '../../ScrollSection'
import Spacer from '../../Spacer'

// Styles
import {
  HowAccordionBox,
  HowAccordionMain,
  HowAccordionNav,
  HowAccordionNavButton,
  HowAccordionNavItem,
  HowAccordionSpacer,
  HowAccordionWrapper,
  AccordionImage,
  HowAccordionNumbers,
  HowAccordionNumber,
  HowAccordionNumberInner,
  HowAccordionNumberText,
  HowAccordionNumberTextBackground,
} from './index.style'
import { Heading2 } from '../../TextStyles'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { HowAccordionContentContainer } from '../HowAccordionContent/index.style'

import { StoreDispatch, StoreState } from '../../../Store'

const numbers = [`01`, `02`, `03`]

export const getProgress = (el, scrollY) => {
  const slideHeight = el.bottom - el.top
  const progress = gsap.utils.clamp(
    0,
    1,
    (scrollY - (el.top - slideHeight)) / slideHeight
  )
  return progress
}

const HowAccordion = ({ data }) => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)
  const accordionImageRefs = useRef([])
  const accordionNumberRefs = useRef([])
  const accordionTextRefs = useRef([])
  const accordionTextBackgroundRefs = useRef([])
  const accordionBackgroundRefs = useRef([])
  const timelineRef = useRef(null)

  const killTimeline = () => {
    gsap.killTweensOf(timelineRef.current)
    timelineRef.current.kill()
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.desk) {
      dispatch({ type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION', payload: 0 })
    }
  }, [dispatch])

  useEffect(() => {
    const distance = accordionTextBackgroundRefs.current[0].offsetHeight * 0.4
    const duration = 0.3

    if (timelineRef.current) {
      killTimeline()
    }

    timelineRef.current = gsap.timeline({
      paused: true,
      defaults: {
        duration: duration,
        ease: 'power2.inOut',
      },
    })

    timelineRef.current
      .to(accordionBackgroundRefs.current, {
        scaleY: 0,
      })
      .to(
        accordionNumberRefs.current[0],
        {
          y: -distance,
        },
        '<'
      )
      .to(
        accordionNumberRefs.current[2],
        {
          y: distance,
        },
        '<'
      )
      .to(
        [accordionTextBackgroundRefs.current],
        {
          opacity: 0,
        },
        '<'
      )

    if (store.howAccordionActiveIndex > -1) {
      timelineRef.current.set(
        accordionNumberRefs.current[store.howAccordionActiveIndex],
        {
          zIndex: 3,
        }
      )
    }

    const inactiveNumbers = accordionNumberRefs.current.filter(
      (el, index) => index !== store.howAccordionActiveIndex
    )

    inactiveNumbers.forEach((number, index) => {
      if (store.howAccordionActiveIndex === 0) {
        timelineRef.current.set(number, {
          zIndex: inactiveNumbers.length - index,
        })
      } else {
        timelineRef.current.set(number, { zIndex: index + 1 })
      }
    })

    timelineRef.current
      .to(accordionBackgroundRefs.current, {
        scaleY: 1,
        delay: 0.1,
      })
      .to(
        accordionNumberRefs.current,
        {
          y: 0,
        },
        '<'
      )

    if (store.howAccordionActiveIndex > -1) {
      timelineRef.current.to(
        accordionTextBackgroundRefs.current[
          Math.max(store.howAccordionActiveIndex, 0)
        ],
        {
          opacity: 1,
        },
        '<'
      )
    }

    timelineRef.current.play()
  }, [store.howAccordionActiveIndex])

  useEffect(() => {
    return () => {
      killTimeline()
    }
  }, [])

  return (
    <ScrollSection threshold={0}>
      <>
        <Spacer size={[1, 75]} />

        <HowAccordionWrapper id="HowAccordion">
          <div
            data-scroll
            data-scroll-sticky
            data-scroll-target="#HowAccordion"
          >
            <HowAccordionMain>
              <Spacer size={[32, 160]} />

              <Container>
                <HowAccordionNav>
                  {React.Children.toArray(
                    data.map((item, itemIndex) => (
                      <HowAccordionNavItem
                        open={store.howAccordionActiveIndex === itemIndex}
                      >
                        <AnimateSlideIn delay={itemIndex * 0.1}>
                          <HowAccordionNavButton
                            onClick={() =>
                              dispatch({
                                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                                payload: itemIndex,
                              })
                            }
                          >
                            <Heading2 as="span">{item.title}</Heading2>
                          </HowAccordionNavButton>
                        </AnimateSlideIn>
                      </HowAccordionNavItem>
                    ))
                  )}
                </HowAccordionNav>

                <Grid>
                  <GridItem desk={7} deskStart={6}>
                    <AnimateSlideIn>
                      <HowAccordionBox>
                        <HowAccordionNumbers>
                          {numbers.map((number, index) => {
                            return (
                              <HowAccordionNumber
                                activeIndex={store.howAccordionActiveIndex}
                                slideIndex={index}
                                ref={ref =>
                                  (accordionNumberRefs.current[index] = ref)
                                }
                                key={index}
                              >
                                <HowAccordionNumberInner
                                  ref={ref =>
                                    (accordionBackgroundRefs.current[
                                      index
                                    ] = ref)
                                  }
                                ></HowAccordionNumberInner>
                                <HowAccordionNumberText
                                  ref={ref =>
                                    (accordionTextRefs.current[index] = ref)
                                  }
                                >
                                  {number}
                                </HowAccordionNumberText>
                                <HowAccordionNumberTextBackground
                                  ref={ref =>
                                    (accordionTextBackgroundRefs.current[
                                      index
                                    ] = ref)
                                  }
                                >
                                  {number}
                                </HowAccordionNumberTextBackground>
                              </HowAccordionNumber>
                            )
                          })}
                        </HowAccordionNumbers>

                        {React.Children.toArray(
                          data.map((slide, slideIndex) => {
                            return (
                              <AccordionImage
                                ref={ref =>
                                  (accordionImageRefs.current[slideIndex] = ref)
                                }
                                activeIndex={store.howAccordionActiveIndex}
                                slideIndex={slideIndex}
                                key={slideIndex}
                              >
                                <GatsbyImage
                                  image={slide.image.gatsbyImageData}
                                  alt={''}
                                />
                              </AccordionImage>
                            )
                          })
                        )}
                      </HowAccordionBox>
                    </AnimateSlideIn>
                  </GridItem>

                  <GridItem deskAlign="center" desk={3} deskStart={2}>
                    <HowAccordionContentContainer>
                      {React.Children.toArray(
                        data.map((item, itemIndex) => (
                          <HowAccordionContent
                            open={itemIndex === store.howAccordionActiveIndex}
                            title={item.title}
                            content={item.content}
                            progressIndex={itemIndex}
                          />
                        ))
                      )}
                    </HowAccordionContentContainer>
                  </GridItem>
                </Grid>
              </Container>
            </HowAccordionMain>
          </div>

          <HowAccordionSpacer
            data-scroll
            data-scroll-call="howSlideA"
            data-scroll-repeat
          />
          <HowAccordionSpacer
            data-scroll
            data-scroll-call="howSlideB"
            data-scroll-repeat
          />
          <HowAccordionSpacer
            data-scroll
            data-scroll-call="howSlideC"
            data-scroll-repeat
          />
        </HowAccordionWrapper>
      </>
    </ScrollSection>
  )
}

HowAccordion.propTypes = {
  data: PropTypes.array,
}

export default HowAccordion
