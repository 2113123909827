import React, { useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  NewsletterClose,
  NewsletterImage,
  NewsletterPopUpContent,
  NewsletterPopUpMain,
  NewsletterSubmit,
} from './index.style'
import { Heading3 } from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import Spacer from '../Spacer'
import RichText from '../RichText'
import { colors } from '../../styles/vars/colors.style'
import {
  ContactFormCheckboxRow,
  ContactFormCheckbox,
  ContactFormCheckboxLabel,
  ContactFormCheckboxMarker,
  ContactFormField,
  ContactFormInput,
  ContactFormLabel,
} from '../ContactForm/index.style'
import IconClose from '../svgs/IconClose'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StoreDispatch } from '../../Store'

const NewsletterPopUp = () => {
  const {
    contentfulNewsletterPopUp: {
      title,
      text,
      emailLabel,
      formSubmitText,
      image,
    },
    contentfulContactForm: { legalCheckboxText, marketingCheckboxText },
  } = useStaticQuery(graphql`
    query {
      contentfulNewsletterPopUp {
        title
        text {
          raw
        }
        emailLabel
        formSubmitText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      contentfulContactForm {
        legalCheckboxText {
          raw
        }
        marketingCheckboxText {
          raw
        }
      }
    }
  `)
  const [legal, setLegal] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [name, setName] = useState('')
  const dispatch = useContext(StoreDispatch)

  const handleLegalClick = e => {
    e.preventDefault()
    setLegal(!legal)
  }

  const handleMarketingClick = e => {
    e.preventDefault()
    setMarketing(!marketing)
  }

  return (
    <NewsletterPopUpMain>
      <NewsletterClose
        onClick={() => dispatch({ type: 'CLOSE_NEWSLETTER_POP_UP' })}
      >
        <IconClose responsive />
      </NewsletterClose>

      <NewsletterPopUpContent>
        <Heading3 as="h2" color={colors.blue}>
          <AnimateSplitText>{title}</AnimateSplitText>
        </Heading3>

        <Spacer size={[15, 28]} />

        <RichText content={text} paragraphSize="regular" />

        <Spacer size={[30, 33]} />

        <ContactFormCheckboxRow>
          <ContactFormCheckbox
            type="checkbox"
            id="contact-legal"
            name="legal"
            checked={legal}
            onChange={() => setLegal(!legal)}
            required
          />

          <ContactFormCheckboxMarker
            onClick={handleLegalClick}
            aria-label="Checkbox"
          />

          <ContactFormCheckboxLabel htmlFor="contact-legal">
            <RichText content={legalCheckboxText} paragraphSize="small" />
          </ContactFormCheckboxLabel>
        </ContactFormCheckboxRow>

        <Spacer size={18} />

        <ContactFormCheckboxRow>
          <ContactFormCheckbox
            type="checkbox"
            id="contact-marketing"
            name="contact-marketing"
            checked={marketing}
            onChange={() => setMarketing(!marketing)}
          />

          <ContactFormCheckboxMarker
            onClick={handleMarketingClick}
            aria-label="Checkbox"
          />

          <ContactFormCheckboxLabel htmlFor="contact-marketing">
            <RichText content={marketingCheckboxText} paragraphSize="small" />
          </ContactFormCheckboxLabel>
        </ContactFormCheckboxRow>

        <Spacer size={[32, 36]} />

        <form
          action="https://firstfit.us18.list-manage.com/subscribe/post?u=d698adbee04834c2e3ffb17ef&amp;id=c887cd4b47"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <ContactFormField>
            <ContactFormInput
              id="mce-EMAIL"
              name="EMAIL"
              type="email"
              placeholder=" "
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <ContactFormLabel htmlFor="mce-EMAIL">
              {emailLabel}
            </ContactFormLabel>
          </ContactFormField>

          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_d698adbee04834c2e3ffb17ef_c887cd4b47"
              tabIndex="-1"
              value=""
              readOnly
            />
          </div>

          <NewsletterSubmit name="subscribe" id="mc-embedded-subscribe">
            <span>{formSubmitText}</span>
            <svg width="17" height="8" fill="none" viewBox="0 0 17 8">
              <path
                fill="#F0F1F2"
                d="M16.354 4.354a.5.5 0 0 0 0-.708L13.172.464a.5.5 0 1 0-.708.708L15.293 4l-2.829 2.828a.5.5 0 1 0 .708.708l3.182-3.182ZM0 4.5h16v-1H0v1Z"
              />
            </svg>
          </NewsletterSubmit>
        </form>
      </NewsletterPopUpContent>

      <NewsletterImage>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </NewsletterImage>
    </NewsletterPopUpMain>
  )
}

export default NewsletterPopUp
