import { transparentize } from 'polished'
import { css } from 'styled-components'
import { colors } from '../vars/colors.style'
import leftArrowIconLight from './images/slickArrowPrevLight.svg'
import rightArrowIconLight from './images/slickArrowNextLight.svg'
import leftArrowIconDark from './images/slickArrowPrevDark.svg'
import rightArrowIconDark from './images/slickArrowNextDark.svg'

export const slickTheme = css`
  .slick-track {
    display: flex;
  }

  .slick-list {
    &.dragging {
      cursor: none;
    }
  }

  .slick-arrow {
    width: 3.6rem;
    height: 3.6rem;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 110%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: 0.1rem solid ${transparentize(0.7, colors.lightGrey)};
    border-radius: 50%;
    transition: opacity 0.2s ease;

    &.slick-disabled {
      opacity: 0.5;
    }
  }

  .slick-prev {
    background-image: url(${leftArrowIconLight});

    .slick-slider--dark & {
      background-image: url(${leftArrowIconDark});
    }
  }

  .slick-next {
    background-image: url(${rightArrowIconLight});

    .slick-slider--dark & {
      background-image: url(${rightArrowIconDark});
    }
  }
`
