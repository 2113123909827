import React from 'react'
import PropTypes from 'prop-types'
import { ScrollSectionMain, ScrollBlock } from './index.style'

const ScrollSection = ({
  id,
  threshold,
  triggerOnce,
  header,
  children,
  ...props
}) => {
  return (
    <ScrollSectionMain id={id} header={header} data-scroll-section {...props}>
      <ScrollBlock>{children}</ScrollBlock>
    </ScrollSectionMain>
  )
}

ScrollSection.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number,
  triggerOnce: PropTypes.bool,
  header: PropTypes.bool,
}

ScrollSection.defaultProps = {
  threshold: 0.1,
  triggerOnce: true,
}

export default ScrollSection
