import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { mq } from '../../styles/vars/media-queries.style'

export const MenuToggleWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.mobileNavButtons};
`

export const MenuToggleMain = styled.button`
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  background-color: transparent;
  border: none;

  ${mq.desk} {
    display: none;
  }
`

export const MenuToggleBar = styled.div`
  position: absolute;
  top: calc(50% - 0.05rem);
  left: calc(50% - 1.8rem);
  width: 3.6rem;
  height: 0.1rem;
  background-color: ${props => (props.light ? colors.light : colors.darkGrey)};

  :first-child {
    transform: translateY(-0.4rem);
  }

  :last-child {
    transform: translateY(0.4rem);
  }
`
