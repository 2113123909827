import styled from 'styled-components'
import { flow } from '../../styles/utils/functions.style'
import { easings } from '../../styles/vars/easings.style'

export const RichTextMain = styled.div`
  ${flow()}
`

export const SmallText = styled.sup``

export const UnorderedList = styled.ul`
  list-style: none;
`

export const OrderedList = styled.ol`
  list-style: none;
`

export const ListItem = styled.li`
  & + & {
    margin-top: 0.5em;
  }

  p {
    position: relative;
    padding-left: 0.75em;

    :before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      transform: translateY(${props => (props.inView ? 0 : '1rem')});
      display: block;
      width: 0.3rem;
      height: 0.3rem;
      overflow: hidden;
      border-radius: 50%;
      background-color: currentColor;
      opacity: ${props => (props.inView ? 1 : 0)};
      transition: transform 0.5s ${easings.inOut.default},
        opacity 0.5s ${easings.inOut.default};
      transition-delay: ${props => `${props.delay}s`};
      will-change: transform, opacity;
    }
  }
`
