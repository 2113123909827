import React from 'react'
import PropTypes from 'prop-types'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {
  Heading3,
  Title,
  TitleSmall,
  TextBody,
  TextBodySmall,
} from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import {
  ListItem,
  OrderedList,
  RichTextMain,
  UnorderedList,
} from './index.style'
import { animation } from '../../styles/vars/animation.style'
import { Heading1, Heading2 } from '../../styles/vars/textStyles.style'
import InViewSection from '../InViewSection'

const getBlockDuration = content => {
  let wordLength = 0

  content.forEach(firstChild => {
    if (firstChild.length) {
      firstChild.forEach(secondChild => {
        if (typeof secondChild === 'string') {
          wordLength += secondChild.split(' ').length
        } else {
          secondChild.props.children.forEach(thirdChild => {
            if (typeof thirdChild === 'string') {
              wordLength += thirdChild.split(' ').length
            }
          })
        }
      })
    } else {
      if (typeof firstChild.type === 'function') {
        wordLength += firstChild.props.children.props.children[0][0].split(' ')
          .length
      } else {
        const child = firstChild.props.children[0]
        if (typeof child === 'string') {
          wordLength += child.split(' ').length
        } else if (child && child.length) {
          child.forEach(item => {
            wordLength += item.split(' ').length
          })
        }
      }
    }
  })

  return wordLength * animation.wordDuration
}

const RichText = ({
  content,
  delay,
  paragraphSize,
  heading1,
  heading2,
  heading3,
  gradient,
  color,
  animate,
  readyToSplit,
}) => {
  let blockDurations = [0]

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.CODE]: text => <i className="super">{text}</i>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const blockDelay = blockDurations.reduce(
          (accumulator, value) => accumulator + value
        )

        blockDurations.push(getBlockDuration(children))

        if (heading1) {
          if (gradient) {
            return (
              <Heading1 as="h3" lineHeight={1.1} color={color}>
                <AnimateSplitText
                  readyToSplit={readyToSplit}
                  delay={delay}
                  animate={animate}
                  heading
                  gradient
                >
                  {children}
                </AnimateSplitText>
              </Heading1>
            )
          } else {
            return (
              <Heading1 as={`h2`} color={color}>
                <AnimateSplitText
                  readyToSplit={readyToSplit}
                  delay={delay}
                  animate={animate}
                >
                  {children}
                </AnimateSplitText>
              </Heading1>
            )
          }
        }
        if (heading2) {
          return (
            <Heading2 color={color}>
              <AnimateSplitText
                readyToSplit={readyToSplit}
                delay={delay}
                animate={animate}
              >
                {children}
              </AnimateSplitText>
            </Heading2>
          )
        }
        if (heading3) {
          return (
            <Heading3 color={color}>
              <AnimateSplitText
                readyToSplit={readyToSplit}
                delay={delay}
                animate={animate}
              >
                {children}
              </AnimateSplitText>
            </Heading3>
          )
        }
        if (paragraphSize === 'regular') {
          return (
            <TextBody color={color}>
              <AnimateSplitText
                readyToSplit={readyToSplit}
                delay={delay + blockDelay}
                animate={animate}
              >
                {children}
              </AnimateSplitText>
            </TextBody>
          )
        }

        if (paragraphSize === 'small') {
          return (
            <TextBodySmall color={color}>
              <AnimateSplitText
                readyToSplit={readyToSplit}
                delay={delay + blockDelay}
                animate={animate}
              >
                {children}
              </AnimateSplitText>
            </TextBodySmall>
          )
        }
      },

      [BLOCKS.HEADING_1]: (node, children) => {
        const blockDelay = blockDurations.reduce(
          (accumulator, value) => accumulator + value
        )

        blockDurations.push(getBlockDuration(children))

        return (
          <Heading3 color={color}>
            <AnimateSplitText
              readyToSplit={readyToSplit}
              delay={delay + blockDelay}
              animate={animate}
            >
              {children}
            </AnimateSplitText>
          </Heading3>
        )
      },

      [BLOCKS.HEADING_2]: (node, children) => {
        const blockDelay = blockDurations.reduce(
          (accumulator, value) => accumulator + value
        )

        blockDurations.push(getBlockDuration(children))

        return (
          <Title color={color}>
            <AnimateSplitText
              readyToSplit={readyToSplit}
              delay={delay + blockDelay}
              animate={animate}
            >
              {children}
            </AnimateSplitText>
          </Title>
        )
      },

      [BLOCKS.HEADING_3]: (node, children) => {
        const blockDelay = blockDurations.reduce(
          (accumulator, value) => accumulator + value
        )

        blockDurations.push(getBlockDuration(children))

        return (
          <TitleSmall color={color}>
            <AnimateSplitText
              readyToSplit={readyToSplit}
              delay={delay + blockDelay}
              animate={animate}
            >
              {children}
            </AnimateSplitText>
          </TitleSmall>
        )
      },

      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),

      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),

      [BLOCKS.LIST_ITEM]: (node, children) => {
        const blockDelay = blockDurations.reduce(
          (accumulator, value) => accumulator + value
        )

        blockDurations.push(getBlockDuration(children))

        return (
          <InViewSection animate={animate}>
            <ListItem delay={delay + blockDelay}>{children}</ListItem>
          </InViewSection>
        )
      },

      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} rel="noreferrer">
          {children}
        </a>
      ),
    },
    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  return <RichTextMain>{renderRichText(content, options)}</RichTextMain>
}

RichText.propTypes = {
  content: PropTypes.object,
  delay: PropTypes.number,
  color: PropTypes.string,
  animate: PropTypes.bool,
  readyToSplit: PropTypes.bool,
  paragraphSize: PropTypes.oneOf(['small', 'regular']),
}

RichText.defaultProps = {
  paragraphSize: 'small',
  animate: true,
  readyToSplit: true,
  delay: 0,
}

export default RichText
