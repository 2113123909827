import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

const InViewSection = ({ threshold, triggerOnce, animate, children }) => (
  <InView triggerOnce={triggerOnce} threshold={threshold}>
    {({ inView, ref }) => (
      <div ref={ref} data-inview={animate ? inView.toString() : false}>
        {React.cloneElement(children, { inView: inView && animate })}
      </div>
    )}
  </InView>
)

InViewSection.propTypes = {
  threshold: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

InViewSection.defaultProps = {
  triggerOnce: true,
  animate: true,
  threshold: 0.2,
}

export default InViewSection
