import React from 'react'
import { forwardRef } from 'react'
import {
  Super,
  Heading1,
  Heading2,
  Heading3,
  Title,
  TitleSmall,
  TextBodyBold,
  TextBody,
  TextBodySmall,
} from '../../styles/vars/textStyles.style'

const ThisSuper = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Super ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Super>
  )
)

const ThisHeading1 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading1 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading1>
  )
)

const ThisHeading2 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading2 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading2>
  )
)

const ThisHeading3 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading3 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading3>
  )
)

const ThisTitle = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Title ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Title>
  )
)

const ThisTitleSmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TitleSmall ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TitleSmall>
  )
)

const ThisTextBodyBold = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodyBold
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodyBold>
  )
)

const ThisTextBody = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBody ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextBody>
  )
)

const ThisTextBodySmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodySmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodySmall>
  )
)

export {
  ThisSuper as Super,
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisTitle as Title,
  ThisTitleSmall as TitleSmall,
  ThisTextBodyBold as TextBodyBold,
  ThisTextBody as TextBody,
  ThisTextBodySmall as TextBodySmall,
}
