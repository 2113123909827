import React from 'react'

const IconArrowRight = ({
  width = 17,
  height = 8,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 8"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M16.354 4.354a.5.5 0 000-.708L13.172.464a.5.5 0 10-.708.708L15.293 4l-2.829 2.828a.5.5 0 10.708.708l3.182-3.182zM0 4.5h16v-1H0v1z"
      />
    </svg>
  )
}

export default IconArrowRight
