import styled from 'styled-components'
import { Heading2 } from '../../../styles/vars/textStyles.style'
import { transparentize } from 'polished'
import { easings } from '../../../styles/vars/easings.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { colors } from '../../../styles/vars/colors.style'

export const HowAccordionContentContainer = styled.div`
  ${mq.deskMax} {
    padding-top: 1.6rem;
  }
`

export const HowAccordionContentMain = styled.div`
  position: relative;

  ${Heading2} {
    display: none;

    ${mq.desk} {
      display: block;
      padding-top: ${20 / 54}em;
      margin-bottom: ${27 / 54}em;
      color: ${props =>
        props.open ? colors.blue : transparentize(0.5, colors.midGrey)};
      transition: color 0.3s ${easings.inOut.default};
    }
  }
`

export const HowAccordionContentBorder = styled.div`
  display: none;
  height: 0.1rem;
  background-color: ${transparentize(0.76, colors.midGrey)};

  ${mq.desk} {
    display: block;
  }
`

export const HowAccordionContentWrapper = styled.div.attrs(props => ({
  style: {
    maxHeight: props.open ? `${props.height}px` : '0px',
  },
}))`
  overflow: hidden;
  transition: max-height ${props => (props.open ? '0.3s' : '0.5s')}
    ${easings.inOut.default};

  ${mq.desk} {
    transition: max-height ${props => (props.open ? '0.6s' : '0.4s')}
      ${easings.inOut.default};
  }
`

export const HowAccordionContentInner = styled.div`
  ${mq.tabletL} {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.desk} {
    max-width: unset;
    padding-bottom: 3.2rem;
  }
`

export const HowAccordionScrollProgress = styled.div.attrs(props => {
  let progressVar

  if (props.progressIndex === 0) {
    progressVar = 'progressA'
  }

  if (props.progressIndex === 1) {
    progressVar = 'progressB'
  }

  if (props.progressIndex === 2) {
    progressVar = 'progressC'
  }

  return {
    style: {
      opacity: props.open ? 1 : 0,
      transform: `scaleX(var(--${progressVar}))`,
    },
  }
})`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  height: 0.1rem;
  background-color: ${colors.blue};
  transition: opacity 0.2s ${easings.inOut.default};
`
