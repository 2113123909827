import styled from 'styled-components'
import { css } from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'

export const textStylesConfig = {
  super: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 62,
      max: 220,
    },
    lineHeight: 1,
    letterSpacing: -0.03,
  },
  heading1: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 54,
      max: 96,
    },
    lineHeight: 1.14,
    letterSpacing: -0.02,
  },
  heading2: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 28,
      max: 54,
    },
    lineHeight: 1.25,
    letterSpacing: -0.02,
  },
  heading3: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 24,
      max: 36,
    },
    lineHeight: 1.25,
    letterSpacing: -0.01,
  },
  title: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 18,
      max: 20,
    },
    lineHeight: 1.25,
    letterSpacing: -0.01,
    uppercase: true,
  },
  titleSmall: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.25,
    uppercase: true,
  },
  bodyBold: {
    family: font.primary.family,
    weight: font.primary.weight.semibold,
    size: {
      min: 16,
      max: 20,
    },
    lineHeight: 1.35,
    letterSpacing: -0.01,
  },
  body: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 16,
      max: 20,
    },
    lineHeight: 1.35,
    letterSpacing: -0.01,
  },
  bodySmall: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.35,
    letterSpacing: -0.01,
  },
}

const getStyles = style => `
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${clamp('font-size', style.size.min, style.size.max)}
  ${style.uppercase ? 'text-transform: uppercase' : ''};
`

export const SuperStyles = css`
  ${getStyles(textStylesConfig.super)}
`

export const Heading1Styles = css`
  ${getStyles(textStylesConfig.heading1)}
`

export const Heading2Styles = css`
  ${getStyles(textStylesConfig.heading2)}
`

export const Heading3Styles = css`
  ${getStyles(textStylesConfig.heading3)}
`

export const TitleStyles = css`
  ${getStyles(textStylesConfig.title)}
`

export const TitleSmallStyles = css`
  ${getStyles(textStylesConfig.titleSmall)}
`

export const TextBodyBoldStyles = css`
  ${getStyles(textStylesConfig.bodyBold)}
`

export const TextBodyStyles = css`
  ${getStyles(textStylesConfig.body)}
`

export const TextBodySmallStyles = css`
  ${getStyles(textStylesConfig.bodySmall)}
`

export const Super = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${SuperStyles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading1 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${Heading1Styles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading2 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${Heading2Styles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading3 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${Heading3Styles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const Title = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TitleStyles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const TitleSmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TitleSmallStyles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyBold = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TextBodyBoldStyles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBody = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TextBodyStyles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodySmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${TextBodySmallStyles}
  line-height: ${props => (props.lineHeight ? props.lineHeight : undefined)};
  color: ${props => (props.color ? props.color : undefined)};
`
