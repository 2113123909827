import styled, { css } from 'styled-components'
import { setVh, clamp } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { easings } from '../../styles/vars/easings.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { ContainerMain } from '../Container/index.style'
import MobileNavBackgroundImage from './images/mobile-menu-background.jpg'
import { transparentize } from 'polished'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { mq } from '../../styles/vars/media-queries.style'
import { animation } from '../../styles/vars/animation.style'

export const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
`

export const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;

  ${mq.desk} {
    align-items: flex-end;
    padding-top: 2.2rem;
    padding-bottom: 0;
  }

  > ${PageTransitionLinkMain} {
    z-index: ${zIndex.mobileNavButtons};

    ${mq.desk} {
      flex: 1;
    }
  }
`

/**
 * Header menu
 */

const headerNavStyles = css`
  display: none;

  ${mq.desk} {
    display: block;
  }
`

export const Menu = styled.ul`
  display: flex;
  list-style: none;
`

export const MenuItem = styled.li`
  :not(:first-child) {
    margin-left: 3.5rem;
  }

  a {
    position: relative;
    display: block;
    padding-top: 2.8rem;
    font-size: 1.6rem;
    font-weight: ${font.primary.weight.medium};
    line-height: 1;
    letter-spacing: -0.01em;
    color: ${props => (props.light ? colors.lightGrey : colors.darkGrey)};
    border-bottom: none;

    :before {
      content: '';
      position: absolute;
      top: calc(100% + 0.8rem);
      right: 0;
      left: 0;
      transform: ${props => (props.active ? 'scaleX(1)' : 'scaleX(0)')};
      transform-origin: 50% 50%;
      display: block;
      height: 0.1rem;
      background-color: ${colors.lightBlue};
      transition: transform 0.15s ${easings.inOut.default};
      transition-delay: ${props =>
        props.active ? `${animation.maskHideDuration}s` : ``};
    }

    :after {
      content: '';
      position: absolute;
      top: calc(100% + 0.8rem);
      right: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 100% 50%;
      display: block;
      height: 0.1rem;
      background-color: ${colors.lightBlue};
      transition: transform 0.15s ${easings.inOut.default} 0s;
    }

    &:hover {
      :after {
        transform: scaleX(1);
        transform-origin: 0% 50%;
      }
    }
  }
`

export const PrimaryNav = styled.nav`
  ${headerNavStyles}
  flex: 2;
  text-align: center;

  ${Menu} {
    justify-content: center;
  }
`

export const SecondaryNav = styled.nav`
  ${headerNavStyles}
  flex: 1;
  text-align: right;

  ${Menu} {
    justify-content: flex-end;
  }

  ${MenuItem}:last-child ${PageTransitionLinkMain} {
    display: flex;
    align-items: center;

    :after {
      content: '';
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      margin-left: 0.8rem;
      background-color: ${colors.lightBlue};
      border-radius: 50%;
      transform: none;
      position: relative;
    }
  }
`

/**
 * Mobile menu
 */

export const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  width: 100%;
  ${setVh('height', 100, true)}
  ${clamp('padding-top', 84, 109, breakpoints.tiny, breakpoints.desk)}
  ${clamp('padding-bottom', 84, 109, breakpoints.tiny, breakpoints.desk)}
  z-index: ${zIndex.mobileNav};
  pointer-events: ${props => (props.open ? undefined : 'none')};
  background-color: ${colors.blue};
  transition: transform 0.2s ${easings.inOut.default};

  ${mq.desk} {
    display: none;
  }
`

export const MobileNavBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${MobileNavBackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
`

export const MobileNavMain = styled.nav`
  position: relative;
  height: 100%;
  display: grid;
  place-items: center;
  overflow: auto;

  ${ContainerMain} {
    max-height: 100%;
    overflow: auto;
  }
`

export const MobileMenu = styled.ul`
  width: 100%;
  list-style: none;
`

export const MobileMenuText = styled.span`
  display: block;
`

export const MobileMenuItem = styled.li`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${colors.lightBlue};
    opacity: ${props => (props.active ? 1 : 0)};
  }

  a {
    display: block;
    ${clamp('font-size', 24, 32, breakpoints.tiny, breakpoints.desk)}
    line-height: 2.5;
    letter-spacing: -0.01em;
    color: ${colors.lightGrey};
    border: none;
  }

  ${MobileMenuText} {
    transform: translateX(${props => (props.active ? '2rem' : '0')});
  }

  svg {
    position: absolute;
    top: calc(50% - 0.4rem);
    right: 0;
  }
`

export const MobileMenuItemBorder = styled.div`
  height: 0.1rem;
  background-color: ${colors.lightGrey};
  opacity: 0.1;
`

export const MobileMenuLink = styled.a``

export const MobileMenuEmail = styled.a`
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  ${clamp('font-size', 20, 26, breakpoints.tiny, breakpoints.desk)}
  line-height: 4.2;
  letter-spacing: -0.01em;
  color: ${colors.light};
  text-align: center;
  border: none;
  border-top: 0.1rem solid ${transparentize(0.8, colors.lightGrey)};
`
