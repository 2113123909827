import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CursorDrag, CursorWrapper } from './index.style'
import { StoreState } from '../../Store'

const Cursor = ({ x, y }) => {
  const store = useContext(StoreState)

  return (
    <CursorWrapper x={x} y={y}>
      <CursorDrag open={store.showCursor} />
    </CursorWrapper>
  )
}

Cursor.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

export default Cursor
