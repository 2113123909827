import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import dragIcon from './images/drag-icon.svg'
import dragIconEs from './images/drag-icon-es.svg'
import dragIconFr from './images/drag-icon-fr.svg'

export const CursorWrapper = styled.div.attrs(({ x, y, show }) => ({
  style: {
    transform: `translate(${x}px, ${y}px)`,
  },
}))`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: ${zIndex.cursor};
  will-change: transform;
`

export const CursorDrag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) =>
    open ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'};
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 50%;
  background-color: ${colors.blue};
  background-image: url(${process.env.GATSBY_CONTENTFUL_LOCALE === 'es'
    ? dragIconEs
    : process.env.GATSBY_CONTENTFUL_LOCALE === 'fr'
    ? dragIconFr
    : dragIcon});
  background-position: center;
  background-repeat: no-repeat;
  will-change: transform;
  transition: transform 0.2s ${easings.inOut.default};
`
