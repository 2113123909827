export const colors = {
  dark: '#111112',
  dark2: '#29292A',
  darkGrey: '#222223',
  light: '#FFFFFF',
  lightGrey: '#F0F1F2',
  midGrey: '#8E8E94',
  blue: '#4669E8',
  lightBlue: '#7095FF',
  purple: '#5555B7',
  teal: '#00CCB6',
  numbers: '#c4c4c4',
}
