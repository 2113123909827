import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Logo = ({
  width = 100,
  height = 23,
  fill = colors.light,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 23"
      aria-label="FirstFit"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M0 2.137v20.447h3.97v-9.601h8.153V9.308H3.97V4.523h9.893V.85H1.273C.57.85 0 1.424 0 2.137zM19.57 6.995h-3.817v15.622h3.817V6.995zM17.661 4.826c1.174 0 2.126-.949 2.126-2.12 0-1.17-.952-2.12-2.126-2.12-1.173 0-2.125.95-2.125 2.12 0 1.171.952 2.12 2.125 2.12zM80.064 4.826c1.174 0 2.125-.949 2.125-2.12 0-1.17-.951-2.12-2.125-2.12s-2.125.95-2.125 2.12c0 1.171.951 2.12 2.125 2.12zM26.4 10.298V6.995h-3.817v15.622H26.4v-7.162c.03-3.243 1.893-5.248 4.182-5.248.824 0 1.801.186 2.627.71V7.211c-.734-.403-1.619-.589-2.412-.589-2.138 0-3.847 1.577-4.397 3.675zM42.309 13.355l-1.985-.465c-1.223-.306-1.65-.74-1.65-1.42 0-.833.762-1.358 2.046-1.358 1.924 0 4 .649 5.252 1.666V8.012c-1.223-.835-3.146-1.391-5.099-1.391-3.115 0-6.016 1.298-6.016 4.94 0 2.009 1.1 3.768 4.459 4.539l1.831.432c1.22.305 1.68.77 1.68 1.544 0 .895-.733 1.48-2.107 1.48-2.016 0-4.367-.865-6.017-2.223l-.03 3.797c1.74 1.143 3.847 1.852 5.679 1.852 4.093 0 6.352-1.79 6.352-4.847.002-2.281-1.129-4.04-4.395-4.78zM54.068 2.67h-3.817v3.66a.66.66 0 01-.657.665h-1.909v3.21h2.566v6.267c0 4.384 2.533 6.513 5.925 6.513a8.12 8.12 0 003.236-.71V18.88a4.823 4.823 0 01-2.566.709c-1.496 0-2.778-1.02-2.778-3.334v-6.05h5.344v-3.21h-5.344V2.671zM62.37 2.168v20.449h3.97v-9.601h8.152V9.34h-8.153V4.554h9.893V.88h-12.59a1.28 1.28 0 00-1.273 1.287zM81.972 6.995h-3.817v15.622h3.817V6.995zM95.674 10.205v-3.21h-5.343V2.671h-3.817v3.66a.66.66 0 01-.657.664h-1.909v3.21h2.566v6.267c0 4.384 2.533 6.513 5.924 6.513a8.12 8.12 0 003.236-.71V18.88a4.823 4.823 0 01-2.565.709c-1.497 0-2.778-1.02-2.778-3.334v-6.05h5.343zM100 21.81c0 .654-.528 1.19-1.17 1.19-.65 0-1.18-.536-1.18-1.19 0-.653.53-1.189 1.18-1.189.642 0 1.17.536 1.17 1.19zm-.188 0c0-.549-.443-1.007-.984-1.007a1.009 1.009 0 000 2.016c.541 0 .984-.457.984-1.008zm-.727.096l.276.527h-.327l-.226-.474h-.15v.474h-.294v-1.245h.508c.239 0 .407.158.407.383a.38.38 0 01-.194.335zm-.43-.474v.281h.175c.09 0 .15-.055.15-.14 0-.081-.062-.139-.15-.139h-.175v-.002z"
      />
    </svg>
  )
}

export default Logo
