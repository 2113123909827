// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-helpful-videos-js": () => import("./../../../src/pages/helpful-videos.js" /* webpackChunkName: "component---src-pages-helpful-videos-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-templates-standard-content-page-js": () => import("./../../../src/templates/standard-content-page.js" /* webpackChunkName: "component---src-templates-standard-content-page-js" */)
}

