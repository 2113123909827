import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import {
  CookieBarBtn,
  CookieBarMain,
  CookieBarWrapper,
  CookieBarText,
} from './index.style'

const CookieBar = () => {
  const analyticsKey = 'gatsby-gdpr-google-analytics'
  const fbPixelKey = 'gatsby-gdpr-facebook-pixel'
  const location = useLocation()
  const [show, setShow] = useState(false)

  const {
    contentfulGlobalSettings: { cookieBarText, cookieBarButtonText },
  } = useStaticQuery(graphql`
    query {
      contentfulGlobalSettings {
        contentful_id
        cookieBarText
        cookieBarButtonText
      }
    }
  `)

  useEffect(() => {
    const cookie = document.cookie

    if (cookie.length === 0) {
      setShow(true)
    } else {
      let accepted = cookie
        .split('; ')
        .find(row => row.startsWith(`${analyticsKey}=`))

      if (accepted) {
        accepted = accepted.split('=')[1]
      }

      if (accepted === 'true') {
        setShow(false)
      } else {
        setShow(true)
      }
    }
  }, [])

  const enableCookies = () => {
    document.cookie = `${analyticsKey}=true;path=/`
    document.cookie = `${fbPixelKey}=true;path=/`
    initializeAndTrack(location)
    setShow(false)
  }

  return (
    <CookieBarMain show={show}>
      <CookieBarWrapper>
        <CookieBarText>{cookieBarText}</CookieBarText>

        <CookieBarBtn onClick={enableCookies}>
          {cookieBarButtonText}
        </CookieBarBtn>
      </CookieBarWrapper>
    </CookieBarMain>
  )
}

export default CookieBar
