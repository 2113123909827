import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '../PageTransitionLink'
import Container from '../Container'
import {
  HeaderContainer,
  HeaderMain,
  Menu,
  MenuItem,
  MobileMenu,
  MobileMenuEmail,
  MobileMenuItem,
  MobileMenuItemBorder,
  MobileMenuLink,
  MobileMenuText,
  MobileNav,
  MobileNavBackground,
  MobileNavMain,
  PrimaryNav,
  SecondaryNav,
} from './index.style'
import Logo from '../svgs/Logo'
import { StoreDispatch, StoreState } from '../../Store'
import { colors } from '../../styles/vars/colors.style'
import MenuToggle from '../MenuToggle'
import IconArrowRight from '../svgs/IconArrowRight'

const Header = ({ pathname }) => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)
  const { mobileNavIsOpen } = store
  const {
    site: {
      siteMetadata: { routes, siteLocale },
    },
    contentfulHeader: { loginLabelText, loginUrl },
    contentfulFooter: { contactEmailAddress },
    contentfulHowItWorks: { seoTitle: howItWorksTitle },
    contentfulOurStory: { seoTitle: ourStoryTitle },
    contentfulAcademy: { seoTitle: academyTitle },
    contentfulCompare: { seoTitle: compareTitle },
    contentfulSuccessStories: { seoTitle: successStoriesTitle },
    contentfulContact: { seoTitle: contactTitle },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteLocale
          routes {
            home
            howItWorks
            ourStory
            academy
            compare
            successStories
            contact
            helpfulVideos
            privacyPolicy
            styleguide
          }
        }
      }
      contentfulHeader {
        loginLabelText
        loginUrl
      }
      contentfulFooter {
        contactEmailAddress
      }
      contentfulHowItWorks {
        seoTitle
      }
      contentfulOurStory {
        seoTitle
      }
      contentfulAcademy {
        seoTitle
      }
      contentfulCompare {
        seoTitle
      }
      contentfulSuccessStories {
        seoTitle
      }
      contentfulContact {
        seoTitle
      }
    }
  `)
  const mainMenuItems = [
    {
      title: howItWorksTitle,
      to: routes.howItWorks,
    },
    {
      title: ourStoryTitle,
      to: routes.ourStory,
    },
    {
      title: compareTitle,
      to: routes.compare,
    },
    {
      title: successStoriesTitle,
      to: routes.successStories,
    },
  ]

  // if (siteLocale === 'es' || siteLocale === 'en-US') {
  //   mainMenuItems.splice(2, 0, {
  //     title: academyTitle,
  //     to: routes.academy,
  //   })
  // }

  const secondaryMenuItems = [
    {
      title: loginLabelText,
      to: process.env.GATSBY_LOGIN_URL || loginUrl,
      external: true,
    },
    {
      title: contactTitle,
      to: routes.contact,
    },
  ]

  useEffect(() => {
    if (
      pathname === routes.home ||
      pathname.includes(routes.academy) ||
      pathname.includes(routes.successStories)
    ) {
      dispatch({ type: 'SET_HEADER_LIGHT' })
    } else {
      dispatch({ type: 'SET_HEADER_DARK' })
    }
  }, [dispatch, pathname, routes])

  return (
    <HeaderContainer light={store.mobileNavIsOpen || store.headerIsLight}>
      <Container fullWidth allowOverflow>
        <HeaderMain>
          <PageTransitionLink to={routes.home}>
            <Logo
              fill={
                store.headerIsLight || store.mobileNavIsOpen
                  ? colors.lightGrey
                  : colors.blue
              }
            />
          </PageTransitionLink>

          <MenuToggle light={store.headerIsLight} />

          <PrimaryNav>
            <Menu>
              {React.Children.toArray(
                mainMenuItems.map(navItem => (
                  <MenuItem
                    light={store.mobileNavIsOpen || store.headerIsLight}
                    active={
                      store.pathname
                        ? store.pathname.indexOf(navItem.to) > -1
                        : pathname.indexOf(navItem.to) > -1
                    }
                  >
                    <PageTransitionLink to={navItem.to}>
                      {navItem.title}
                    </PageTransitionLink>
                  </MenuItem>
                ))
              )}
            </Menu>
          </PrimaryNav>

          <SecondaryNav light={store.headerIsLight}>
            <Menu>
              {React.Children.toArray(
                secondaryMenuItems.map(item => (
                  <MenuItem
                    light={store.mobileNavIsOpen || store.headerIsLight}
                  >
                    {item.external ? (
                      <a href={item.to} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    ) : (
                      <PageTransitionLink to={item.to}>
                        {item.title}
                      </PageTransitionLink>
                    )}
                  </MenuItem>
                ))
              )}
            </Menu>
          </SecondaryNav>
        </HeaderMain>
      </Container>

      <MobileNav open={mobileNavIsOpen}>
        <MobileNavBackground />

        <MobileNavMain>
          <Container>
            <MobileMenu>
              {React.Children.toArray(
                mainMenuItems.map(item => (
                  <MobileMenuItem
                    active={
                      store.pathname
                        ? store.pathname.indexOf(item.to) > -1
                        : pathname.indexOf(item.to) > -1
                    }
                  >
                    <PageTransitionLink to={item.to}>
                      <MobileMenuText>{item.title}</MobileMenuText>
                    </PageTransitionLink>
                    <IconArrowRight />
                    <MobileMenuItemBorder />
                  </MobileMenuItem>
                ))
              )}

              {React.Children.toArray(
                secondaryMenuItems.map((item, itemIndex) => (
                  <MobileMenuItem
                    active={
                      store.pathname
                        ? store.pathname.indexOf(item.to) > -1
                        : pathname.indexOf(item.to) > -1
                    }
                  >
                    {item.external ? (
                      <MobileMenuLink href={item.to}>
                        <MobileMenuText>{item.title}</MobileMenuText>
                      </MobileMenuLink>
                    ) : (
                      <PageTransitionLink to={item.to}>
                        <MobileMenuLink as="span">
                          <MobileMenuText>{item.title}</MobileMenuText>
                        </MobileMenuLink>
                      </PageTransitionLink>
                    )}

                    <IconArrowRight />

                    {itemIndex < secondaryMenuItems.length - 1 && (
                      <MobileMenuItemBorder />
                    )}
                  </MobileMenuItem>
                ))
              )}
            </MobileMenu>
          </Container>
        </MobileNavMain>

        <MobileMenuEmail href={`mailto:${contactEmailAddress}`}>
          {contactEmailAddress}
        </MobileMenuEmail>
      </MobileNav>
    </HeaderContainer>
  )
}

export default Header
