import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StoreDispatch, StoreState } from '../../Store'
import { animation } from '../../styles/vars/animation.style'
import { PageTransitionLinkMain } from './index.style'

const PageTransitionLink = ({ children, to, fill, ...props }) => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)

  return (
    <PageTransitionLinkMain
      to={to}
      exit={{
        delay: animation.maskShowDuration,
        length: 0,
        zIndex: 2,
      }}
      entry={{
        delay: 0,
        length: animation.maskHideDuration,
        zIndex: 1,
      }}
      fill={fill ? 'true' : undefined}
      {...props}
      onClick={() => {
        if (
          to.indexOf(store.pathname) === -1 ||
          to.length !== store.pathname.length
        ) {
          dispatch({ type: 'HIDE_MOBILE_NAV' })
          dispatch({ type: 'UPDATE_PATHNAME', payload: to })
          dispatch({ type: 'SHOW_TRANSITION_MASK' })
        }
      }}
    >
      {children}
    </PageTransitionLinkMain>
  )
}

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
}

PageTransitionLink.defaultProps = {
  fill: false,
}

export default PageTransitionLink
