import { useContext, useEffect } from 'react'
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from 'locomotive-scroll'
import { animation } from '../../styles/vars/animation.style'
import { getProgress } from '../HowItWorks/HowAccordion'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { StoreDispatch } from '../../Store'

const Scroll = ({ callbacks, desktop }) => {
  const dispatch = useContext(StoreDispatch)

  useEffect(() => {
    const locomotiveScroll = new LocomotiveScroll({
      el: document.getElementById('scroll-container'),
      smoothMobile: false,
      smooth: true,
      tablet: {
        breakpoint: 768,
        smooth: false,
      },
      direction: 'vertical',
      getDirection: true,
      touchMultiplier: 2.5,
      firefoxMultiplier: 70,
      lerp: 0.1,
      repeat: false,
      class: 'is-inview',
      reloadOnContextChange: true,
    })

    window.scroll = locomotiveScroll
    window.scroll.stop()

    const timeout = setTimeout(() => {
      window.scroll.update()
      window.scroll.start()

      window.scroll.on('scroll', args => {
        if (window.scrollDirection !== args.direction) {
          window.scrollDirection = args.direction
        }

        // How it works fixed progress
        if (window.innerWidth >= breakpoints.desk) {
          if (args.currentElements.el1) {
            document.documentElement.style.setProperty(
              '--progressA',
              `${getProgress(args.currentElements.el1, args.scroll.y)}`
            )
          }
          if (args.currentElements.el2) {
            document.documentElement.style.setProperty(
              '--progressB',
              `${getProgress(args.currentElements.el2, args.scroll.y)}`
            )
          }
          if (args.currentElements.el3) {
            document.documentElement.style.setProperty(
              '--progressC',
              `${getProgress(args.currentElements.el3, args.scroll.y)}`
            )
          }
        }
      })

      window.scroll.on('call', (value, way) => {
        if (window.innerWidth >= breakpoints.desk) {
          if (value === 'howSlideA') {
            if (window.scrollDirection === 'down' && way === 'enter') {
              dispatch({
                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                payload: 0,
              })
            }

            if (window.scrollDirection === 'up' && way === 'exit') {
              dispatch({
                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                payload: -1,
              })
            }
          }

          if (value === 'howSlideB') {
            if (window.scrollDirection === 'down' && way === 'enter') {
              dispatch({
                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                payload: 1,
              })
            }

            if (window.scrollDirection === 'up' && way === 'exit') {
              dispatch({
                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                payload: 0,
              })
            }
          }

          if (value === 'howSlideC') {
            if (window.scrollDirection === 'down' && way === 'enter') {
              dispatch({
                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                payload: 2,
              })
            }

            if (window.scrollDirection === 'up' && way === 'exit') {
              dispatch({
                type: 'SET_HOW_ACCORDION_ACTIVE_INDEX_POSITION',
                payload: 1,
              })
            }
          }
        }
      })
    }, animation.maskHideDuration * 1000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      if (window.scroll) {
        window.scroll.destroy()
      }
    }
  }, [callbacks.pathname, desktop, dispatch])

  return null
}

export default Scroll
