import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { easings } from '../../styles/vars/easings.style'
import { clamp } from '../../styles/utils/conversion.style'

export const CookieBarMain = styled.div`
  position: fixed;
  right: 2.4rem;
  ${clamp('left', 24, 48)}
  ${clamp('bottom', 30, 48)}
  transform: ${({ show }) =>
    show ? 'translateY(0) scale(1)' : 'translateY(100%) scale(0)'};
  transform-origin: 0% 0%;
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: ${zIndex.cookieBar};
  transition: transform 0.3s ${easings.inOut.default},
    opacity 0.25s ${easings.inOut.default};

  ${mq.mobileL} {
    right: auto;
  }
`

export const CookieBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.1rem 4.8rem 1rem;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: -0.01em;
  text-align: center;
  color: ${colors.lightGrey};
  background-color: ${colors.blue};
  border-radius: 2.4rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${mq.mobileL} {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.2rem 2.4rem 0.2rem 1.9rem;
    text-align: left;
  }
`

export const CookieBarText = styled.div`
  ${mq.mobileL} {
    display: flex;
    align-items: center;
    margin-right: 2.4rem;

    :before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 1.1rem;
      border-radius: 50%;
      background-color: ${colors.lightBlue};
    }
  }
`

export const CookieBarBtn = styled.button`
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  color: currentColor;
  text-decoration: underline;
  border: none;
  background: transparent;
`
