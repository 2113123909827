import React from 'react'

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function convertLineBreaksToHTML(string) {
  return string.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment]
  }, [])
}

export function stringToId(str) {
  return str.toLowerCase().replace(/\s/g, '-')
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max)
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function scrollTo(
  target,
  duration = 2000,
  offset = 0,
  callback = false
) {
  const targetEl =
    typeof target === 'string' ? document.getElementById(target) : target

  window.scroll.scrollTo(targetEl, {
    duration,
    offset,
    callback: () => {
      if (callback) {
        callback()
      }

      window.scroll.update()
    },
  })
}
