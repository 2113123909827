export const font = {
  primary: {
    family: 'Roobert',
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
    },
  },
  fallback: {
    family: 'sans-serif',
  },
}
