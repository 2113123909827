import styled, { css } from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { clamp } from '../../styles/utils/conversion.style'
import { TextBodyStyles } from '../../styles/vars/textStyles.style'
import { transparentize } from 'polished'
import selectArrowIcon from './images/select-arrow.svg'
import checkboxTick from './images/checkbox-tick.svg'
import { CtaWrapper } from '../Cta/index.style'
import { easings } from '../../styles/vars/easings.style'

export const ContactFormMain = styled.form`
  position: relative;
  display: grid;
  grid-column-gap: 2.4rem;
  grid-template-columns: repeat(6, 1fr);
  align-items: flex-start;
  grid-template-areas:
    'type type type type type type'
    'name name name name name name'
    'email email email email email email'
    'speciality speciality speciality speciality speciality speciality'
    'position position position position position position'
    'phone phone phone phone phone phone'
    'country country country country country country'
    'message message message message message message'
    'legal legal legal legal legal legal'
    'marketing marketing marketing marketing marketing marketing'
    'submit submit submit submit submit submit';
`

export const ContactFormField = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin-bottom: 2.4rem;
  position: relative;
  grid-area: ${props => props.area};

  ${CtaWrapper} {
    ${clamp('margin-top', 12, 36)}
  }
`

const inputStyles = css`
  appearance: none;
  display: block;
  width: 100%;
  ${clamp('height', 62, 70)}
  ${clamp('padding-left', 24, 31)}
  ${clamp('padding-right', 24, 31)}
  overflow: hidden;
  border: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
  border-radius: 3.5rem;
  background-color: ${colors.lightGrey};
  box-shadow: 0 0 0 0.2rem ${transparentize(1, colors.lightBlue)};
  transition: box-shadow 0.1s ease;

  :not(:focus):not(:placeholder-shown):invalid {
    box-shadow: 0 0 0 0.2rem ${transparentize(0, 'red')};
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${transparentize(0, colors.lightBlue)};
  }
`

export const ContactFormInput = styled.input`
  ${inputStyles}
  padding-top: 1.1rem;
  ${TextBodyStyles}

  ::placeholder {
    opacity: 0;
  }
`

export const ContactFormTextarea = styled.textarea`
  ${inputStyles}
  padding-top: 3rem;
  height: auto;
  resize: none;
  ${TextBodyStyles}

  ::placeholder {
    opacity: 0;
  }
`

export const ContactFormSelectWrapper = styled.div`
  position: relative;

  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 2.8rem;
    transform: translateY(-50%);
    display: block;
    width: 0.9rem;
    height: 0.5rem;
    background-image: url(${selectArrowIcon});
    background-position: center;
    background-repeat: no-repeat;
  }
`

export const ContactFormSelect = styled.select`
  ${inputStyles}
  ${TextBodyStyles}
  padding-top: ${props => (props.value === '' ? '0' : '1.1rem')};

  :not(:focus):not(:placeholder-shown):invalid {
    box-shadow: none;
  }
`

export const ContactFormCheckboxRow = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
`

export const ContactFormCheckbox = styled.input`
  position: absolute;
  right: 100%;
  top: 0;
`

export const ContactFormCheckboxMarker = styled.button`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.8rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0.3rem;
  border: 0.1rem solid ${colors.midGrey};

  ${ContactFormCheckbox}:checked ~ & {
    background-image: url(${checkboxTick});
    background-size: contain;
    background-repeat: no-repeat;
  }
`

export const ContactFormCheckboxLabel = styled.label`
  flex: 1;
  color: ${colors.midGrey};

  a {
    color: ${colors.midGrey};
  }
`

const labelStyles = css`
  position: absolute;
  ${clamp('top', 31, 35)}
  ${clamp('left', 24, 31)}
  transform-origin: 0% 0%;
  ${TextBodyStyles}

  ${ContactFormSelect}[required] + &:after,
  ${ContactFormInput}[required] + &:after,
  ${ContactFormTextarea}[required] + &:after {
    content: '*';
  }
`

export const ContactFormLabel = styled.label`
  ${labelStyles}
  transform: translateY(-50%);
  transition: transform 0.15s ease, color 0.15s ease;

  ${ContactFormField}:focus-within &,
  ${ContactFormInput}:not(:placeholder-shown) + &,
  ${ContactFormTextarea}:not(:placeholder-shown) + & {
    transform: translateY(-84%) scale(0.6);
    color: ${colors.midGrey};
  }
`

export const ContactFormSelectLabel = styled.div`
  ${labelStyles}
  transform: translateY(-84%) scale(0.6);
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.15s ease;
`

export const ContactFormSuccess = styled.div`
  transform: ${props => (props.show ? 'translateY(0)' : 'translateY(100%)')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s ${easings.inOut.default},
    transform 0.3s ${easings.inOut.default};
`
