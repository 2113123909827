import React, { useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { StoreDispatch, StoreState } from '../../Store'
import { TransitionMaskWrapper, TransitionMaskBlock } from './index.style'
import { animation } from '../../styles/vars/animation.style'

const TransitionMask = () => {
  const store = useContext(StoreState)
  const dispatch = useContext(StoreDispatch)
  const maskRef = useRef()

  useEffect(() => {
    const tl = gsap.timeline()
    const mask = maskRef.current

    if (store.showTransitionMask) {
      tl.to(mask, {
        opacity: 1,
        duration: animation.maskShowDuration,
        ease: 'power2.inOut',
      }).to(mask, {
        opacity: 0,
        duration: animation.maskHideDuration,
        ease: 'power2.inOut',
        onComplete: () => dispatch({ type: 'HIDE_TRANSITION_MASK' }),
      })
    }

    return () => {
      if (tl) {
        tl.kill()
      }
    }
  }, [store.showTransitionMask, dispatch])

  useEffect(() => {
    const mask = maskRef.current

    return () => {
      gsap.set(mask, {
        clearProps: 'opacity',
      })
    }
  }, [])

  return (
    <TransitionMaskWrapper show={store.showTransitionMask}>
      <TransitionMaskBlock ref={maskRef} />
    </TransitionMaskWrapper>
  )
}

export default TransitionMask
